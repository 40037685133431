@font-face {
  font-family: Arquitecta;
  src: url('assets/fonts/arquitecta-cufonfonts/ArquitectaBlack.otf')
    format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

/* @font-face {
  font-family: 'Arquitecta';
  src: url('assets/fonts/arquitecta-cufonfonts/ArquitectaBold.otf')
    format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
} */
